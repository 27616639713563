$primary-color: rgba(59, 50, 118, 1); //3B3276
$primary-color-opacity-min: rgba(59, 50, 118, 0.4);
$primary-color-opacity-xmin: rgba(59, 50, 118, 0.1);
$secondary-color: rgba(246, 236, 73, 1); //F6EC49
$secondary-color-opacity-min: rgba(246, 236, 73, 0.6);
$secondary-color-opacity-xmin: rgba(246, 236, 73, 0.1);
$tertiary-color: white;
$primary-font-family: Avenir;
$breakpoint-tablet: 800px;
$breakpoint-tablet-profile: 1300px;
$breakpoint-tablet-display-profile: 1200px;

* {
  margin: 0;
  padding: 0;
}

html {
  scrollbar-color: gray white;
}

html, body {
  font-family: $primary-font-family, sans-serif;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

header {
  z-index: 1000;
}

main {
  flex: 1;
  display: flex;
  justify-content: center;
}

.link_no_css{
  text-decoration: none;
  color: $secondary-color;
}

.home-header {
  background-color: $tertiary-color;
  padding: 20px 20px 20px 20px;
  position: fixed;
  width: 100%;

  .btn-header {
    position: absolute;
    right: 0;
    color: white;
    margin-right: 20px;
    margin-top: 10px;

  }

  .logo-column {
    display: flex;
    justify-content: center;

    #logo-mobil-logged {
      display: block;
      visibility: hidden;
    }

    @media only screen and (max-width: $breakpoint-tablet+1) {
      justify-content: left;
      #logo-desktop {
        display: none;
      }
      #logo-mobil {
        display: block;
      }
      #logo-mobil-logged {
        display: block;
        visibility: hidden;
      }
    }
    @media only screen and (min-width: $breakpoint-tablet) {
      #logo-desktop {
        display: block
      }
      #logo-mobil {
        display: none
      }
    }
  }
}

#header-min {
  display: none;
}


footer {
  background: $primary-color;
  width: 100%;
  height: 100px;
  display: flex !important;
  padding-left: 10%;
  padding-right: 25%;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 10px;
    height: 200px;
  }

  .mention {
    color: white;
  }

  .contact {
    display: flex;
    color: $secondary-color;
    max-width: 500px;
    width: 50%;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }

    hr {
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }

    #contact-us-block {
      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .reseaux {
    display: flex;
    width: 10%;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      width: 25%;
      justify-content: center;
    }
  }
}
@media only screen and (min-width: block_mobile_verison) {
  .block_mobile_verison{
    visibility: hidden;
    display: none;
  }
  .block_desktop_verison{
    visibility: visible;
    display: block;
  }

}

@media only screen and (max-width: block_mobile_verison+1) {
  .block_mobile_verison{
    visibility: visible;
    display: block;
  }
  .block_desktop_verison{
    visibility: hidden;
    display: none;
  }
}

.btn-right {
  display: flex;
  justify-content: right;
}

.font-default {
  color: $primary-color;
  background-color: $tertiary-color;

}

.font-primary {
  color: $tertiary-color;
  background-color: $primary-color;
}


.font-primary-border {
  color: $primary-color;
  background-color: $tertiary-color;

}

.font-primary-min {
  color: $tertiary-color;
  background-color: $primary-color-opacity-min;
}

.font-secondary {
  color: $primary-color;
  background-color: $secondary-color;
}

.font-secondary-min {
  color: $primary-color;
  background-color: $secondary-color-opacity-min;
}

.center-text {

  text-align: center;
}

table {
  width: 100%;
  table-layout: auto;
}

.table_tests {
  margin: 20px;
}

.conteneur-tab-test {
  overflow: scroll;
}


.table-material {
  padding: 20px;
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;

  th {
    padding: 10px;
  }

  td {
    padding: 10px;
  }
}

.th-header {
  text-align: center;
  vertical-align: middle;
}


.text-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: inherit;
}

.text-responsive-vertical {
  writing-mode: horizontal-tb;
  text-orientation: mixed;
  @media only screen and (max-width: 1500px) {
    writing-mode: vertical-rl;
  }
}

.h1-text {
  padding: 10px;
  font-size: 30px;
}

.h2-text {
  padding: 20px;
  font-size: 20px;
}

.h3-text {
  padding: 10px;
}

.test-name {
  width: 100%;
}

.h4-text {
  padding: 10px;
}


.body-primary {
  @media only screen and (min-width: $breakpoint-tablet) {

    margin-top: 200px;
    padding: 20px;
  }
  background-color: $primary-color-opacity-min;

}

@media only screen and (min-width: $breakpoint-tablet) {
  #slide-menu-mobil {
    display: none;
  }

  #section-header {
    display: block;
  }
}

@media only screen and (max-width: $breakpoint-tablet+1) {
  #slide-menu-mobil {
    display: block;
    background-color: $tertiary-color;
  }
  #section-header {
    display: none;
  }

  #home-body {
    margin-top: 0 !important;
  }
}

.btn-header-mobil {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-top: 10px;
  padding-left: calc(100% - 180px);
}

.content-body {
  background-color: $primary-color-opacity-min;

  color: $primary-color;


  .text-block {
    padding: 20px 50px 20px 50px;

    h1 {
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      font-size: 35px;
    }

    h2 {
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      font-size: 20px;
    }

    h3 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 18px;
    }
  }

  .img-block {
    justify-content: center;
    display: flex;

    #montage-home {
      height: auto;
      @media only screen and (min-width: $breakpoint-tablet) {
        max-height: 500px;
      }

      @media only screen and (max-width: $breakpoint-tablet+1) {
        max-height: 200px;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-tablet-profile) {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .img-block {
      padding: 10px 10px 10px 10px; /* haut | droit | bas | gauche */
    }
  }

  .btn-package {
    display: flex;
    justify-content: center;

    .down-block {
      text-align: center;
    }
  }

  #up-part {
    display: none;
    text-align: center;
    padding-top: 200px;
    margin-top: -200px;
  }

  #logo-up {
  }

  #down-part {
    text-align: center;
  }

  #down-logo {
    transform: rotate(180deg);
    text-align: center;


  }

  .package-block {
    padding: 20px 50px 20px 50px;
  }

  .form-package-block {
    display: flex;
    justify-content: center;
  }

  .table-package-block {
    display: flex;
    justify-content: center;
    padding: 20px;

    .table-package {
      background-color: $tertiary-color;
      padding: 30px;
      border-radius: 20px;
      border-spacing: 20px 0rem;
      box-shadow: 12px 12px 2px 1px #3b3276;
      width: auto;
      @media only screen and (max-width: $breakpoint-tablet-display-profile+1) {
        display: none;
      }
      @media only screen and (min-width: $breakpoint-tablet-display-profile) {
        display: block;
      }

      .table-package-main-col {
        text-transform: uppercase;
        text-align: right;
        padding: 20px;
        max-width: 420px;


      }

      .table-package-col {
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        background-color: $secondary-color-opacity-min;
        padding: 20px;
        max-width: 200px;
      }
    }

  }

  #section-end {
    @media only screen and (max-width: $breakpoint-tablet+1) {
      display: block;
    }
    @media only screen and (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  #package-list-mobil {

    @media only screen and (max-width: $breakpoint-tablet-display-profile+1) {
      display: block;
    }
    @media only screen and (min-width: $breakpoint-tablet-display-profile) {
      display: none;
    }

    .panel {
      max-width: 800px;
      background: $tertiary-color;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 20px;
    }

    .panel__title {
      line-height: 1;

    }

    .panel__content {
      background: $secondary-color-opacity-min;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 20px;

    }

    scroll-container {
      overflow-y: scroll;
      scroll-behavior: smooth;

    }

  }

  .link-up {
    text-decoration: none;

    #icon-font-up {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: $secondary-color;
      width: 55px;
      height: 55px;
      margin: 0px;
    }

    #icon-circle-only {
      display: block;
      margin: -3px;
    }

    #icon-font-circle {
      display: block;
      z-index: 1;
      color: $tertiary-color;
      width: 55px;
      height: 55px;
      margin: 0px;
    }
  }

  .link-up:hover {
    #icon-circle-only {
      display: none;
    }

    #icon-font-up {
      color: $tertiary-color;
    }

    #icon-font-circle {
      color: $secondary-color;
    }
  }

  .btn-fixed-down {
    display: flex;
    justify-content: right;
    margin: 20px;
    position: fixed;
    bottom: 70px;
    right: 0;

    #icon-up-id {
      display: none;
    }

    .icon-up {
      position: relative;

      #icon-circle-only {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
      }
    }
  }
}

.subscribe-body {
  padding-top: 130px;
}

.btn {
  background-color: $tertiary-color;
  border-radius: 20px;
  color: $primary-color;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid $primary-color;
}

.btn:hover, .btn-round:hover {
  background-color: $primary-color;
  color: $tertiary-color;
}


.btn-reverse {
  background-color: $primary-color;
  color: $tertiary-color;
}


.btn-edit {
  background-color: $primary-color;
  color: $tertiary-color;
}

.btn-edit:hover {
  background-color: $tertiary-color;
  color: $primary-color;
}

.btn-reverse:hover {
  background-color: $tertiary-color;
  color: $primary-color;
}

.a-hidden {
  text-decoration: none;
}

.a-hidden:hover {
  text-decoration: none;
  color: white;
}


.btn-round {
  background-color: $tertiary-color;
  border-radius: 50px;
  color: $primary-color;
  padding: 10px 10px;
  margin: 10px;
  text-decoration: none;
}

.btn-secondary {
  color: $secondary-color;
  border-radius: 20px;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}

.btn-tertiary {
  background-color: $secondary-color;
  border-radius: 20px;
  color: $primary-color;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;

}

.btn-tertiary:hover {
  color: $secondary-color;
  border: 1px solid $secondary-color;
  background-color: #ffffff;

}

nav {
  width: 100%;
  background-color: white;
  position: absolute;
  position: -webkit-sticky;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
  box-shadow: 500px 0px 0px 0px white, -500px 0px 0px 0px white;
}

nav ul {
  list-style-type: none;
  position: sticky;
}

nav li {
  float: left;
  width: 20%; /*100% divisé par le nombre d'éléments de menu*/
  text-align: center; /*Centre le texte dans les éléments de menu*/

}

/*Evite que le menu n'ait une hauteur nulle*/
nav ul::after {
  content: "";
  display: table;
  clear: both;
}

nav a {
  display: block; /*Toute la surface sera cliquable*/
  text-decoration: none;
  color: $primary-color;
  border-bottom: 2px solid transparent; /*Evite le décalage des éléments sous le menu à cause de la bordure en :hover*/
  padding: 0px 0px; /*Agrandit le menu et espace la bordure du texte*/
}

nav a:hover {
  text-decoration: none;
  color: $primary-color;
  border-bottom: 2px solid $primary-color;
}

*,
*:before,
*:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
}

.burger-check {
  display: none;
}

/*--------------------------------------
 Sets the actionable area for the burger
 including a label for usability
--------------------------------------*/
.burger {
  position: relative;
  float: left;
  margin: 15px;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.burger:after {
  position: absolute;
  bottom: 7px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 13px;
}

/*--------------------------------------
 Creates the look of the burger icon
 using the inner span and pseudo-elements
--------------------------------------*/
.burger span,
.burger span:before,
.burger span:after {
  position: absolute;
  left: 10px;
  top: 22px;
  width: 40px;
  height: 4px;
  background: $primary-color;
  border-radius: 0.2em;
}

/* Reset the left and create the pseudo-element */
.burger span:before,
.burger span:after {
  content: '';
  left: 0;
}

/* Top bar position */
.burger span:before {
  top: -8px;
}

/* Bottom bar position */
.burger span:after {
  top: 8px;
}

/* Get rid of more on action (IE9 or higher) */
.burger-check:checked + .burger:after {
  content: '';
}

/* Get rid of the middle bar on action (IE9 or higher) */

.burger-check:checked + .burger span {
  height: 0;
  width: 0;
}

/* Moves the top and bottom bars to the middle on action (IE9 or higher) */
.burger-check:checked + .burger span:before,
.burger-check:checked + .burger span:after {
  top: 6px;
}

/* Rotates the top bar on action with full browser support (IE9 or higher) */
.burger-check:checked + .burger span:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* Rotates the bottom bar on action with full browser support (IE9 or higher) */
.burger-check:checked + .burger span:after {
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(-225deg);
}

@keyframes rotationFleche {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.a-header {
  margin: 0;
  min-height: 50px;
  position: sticky;
  top: 0; /* required */
  padding-top: 13px
}

.a-header ul {
  position: relative;
  float: left;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.a-header ul a {
  position: relative;
  float: left;
  width: 100%;
  line-height: 50px;
  padding: 0 15px;
  text-decoration: none;
  color: $primary-color;
  text-align: center;
}

.a-header ul a:hover {
  text-decoration: none;
  color: $primary-color;
}

.a-header ul a:active,
.a-header ul a:focus,
.a-header ul a:visited {
  text-decoration: none;
  color: $primary-color;
}


.burger-header {
  margin: 0;
  min-height: 50px;
  overflow: hidden;
  position: sticky;
  top: 0; /* required */
  z-index: 1;

}

.burger-header ul {
  position: relative;
  float: left;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.burger-header ul a {
  position: relative;
  float: left;
  width: 100%;
  height: 0;
  line-height: 50px;
  padding: 0 15px;
  text-decoration: none;
  overflow: hidden;
  color: $primary-color;
  text-align: left;
}

.burger-header ul a:hover {
  text-decoration: none;
  color: $primary-color;
}

.burger-header ul a:active,
.burger-header ul a:focus,
.burger-header ul a:visited {
  text-decoration: underline;
  color: $primary-color;
}


/* Animate height of 'a' element in order to reveal the menu on check (IE9 or higher) */
#main-nav:checked ~ ul a {
  height: 50px;
}


nav > ul {
  display: flex;
  text-align: center;
}

nav ul {
  list-style-type: none;
}

nav > ul > li {
  background-color: $tertiary-color;
  position: relative;
  height: 100%;
  flex: 1;
}

.navbar-mobile {
  background-color: $tertiary-color;
}

.card {
  border-radius: 20px;
  margin: 20px;

  .card-header {
    width: 100%;
    text-align: left;

    h1 {
      display: inline;
    }

    .btn-edit {
      position: absolute;
      right: 120px;
      @media only screen and (max-width: $breakpoint-tablet+1) {
        right: 10%;
      }
    }

    .btn-remove {

      position: absolute;
      right: 60px;

      @media only screen and (max-width: $breakpoint-tablet+1) {
        margin-top: 40px;
        right: 10%;
      }
    }

    .btn-edit-preambule {
      position: absolute;
      right: 180px;
      @media only screen and (max-width: $breakpoint-tablet+1) {
        right: 20%;
      }
    }

    .btn-remove-preambule {
      position: absolute;
      right: 120px;

      @media only screen and (max-width: $breakpoint-tablet+1) {
        margin-top: 40px;
        right: 20%;
      }
    }

  ;
  }

  .card-body {
    display: none;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    background-color: $tertiary-color;
  }

  .page-profil-forme {
    padding: 70px;

    h1 {
      font-size: 52px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .table-profil-forme-id {
    background-color: $tertiary-color;

    th {
      font-size: 38px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .wrapper-ID {
    display: grid;
    grid-template-columns: 20% 80%;

    margin-bottom: 20px;

    text-align: center;

    h1 {
      display: inline;
      font-size: 78px;
      font-weight: 900;
      font-stretch: ultra-expanded;
      padding: 10px;
    }
  }

}

.one {
  vertical-align: middle;
  margin-top: 20px;

  .table-profil-forme-id {
    border-collapse: collapse;

    th {
      border: 3px solid $primary-color;
    }
  }


}

.two {
  vertical-align: text-top;
  padding-left: 50px;

}

.section_id_profil_forme {
  width: 50px;
}

.page-profil-forme-conteneur {
  padding: 50px;
}

.table-personal-data {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;

  td {
    border: 3px solid $primary-color;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
  }

  .none-data {
    min-width: 300px;
  }
}

.sous-texte {
  padding-top: 20px;
  padding-bottom: 50px;
}

.titre-head-type {
  transform: rotate(180deg);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  background-color: $primary-color;
  color: $tertiary-color;
  width: 50px;
}

.profil-forme-test-resultat {
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 3px solid $primary-color;

  td {
    border-collapse: collapse;
    height: 10px;
    padding: 10px;

      &.indice-value {
          background-color: $primary-color;
          color: white;
      }
  }

  .td-10 {
    border: 3px solid $primary-color;
    text-align: center;
    width: 10px;
  }

  p {
    text-align: justify;
  }

    tr {
        &.pair {
            background-color: $tertiary-color;
        }

        &.impair {
            background-color: $primary-color-opacity-min;
        }
    }
}

.align-rgth {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.table-profile {
  position: relative;

  @media only screen and (max-width: $breakpoint-tablet-profile) {
    font-size: 10px;
  }

  .profil-forme-test-resultat.resultat {
    width: 10%;
    position: absolute;
    top: 0;
    right: 0;

    &.resultat-with-title {
      top: -42px;

      @media only screen and (max-width: $breakpoint-tablet-profile) {
        top: -36px;
      }
    }

    tr, th {
      height: 42px;

      @media only screen and (max-width: $breakpoint-tablet-profile) {
        height: 36px;
      }
    }

    th {
      background-color: #3b3276;
      color: #fff;

      @media only screen and (max-width: $breakpoint-tablet-profile) {
        font-size: 8px;
      }
    }
  }
}

.page-default-conteneur {
  @media only screen and (min-width: $breakpoint-tablet) {
    padding-top: 200px;
  }
}

.example-wrapper {
  margin: 1em auto;
  max-width: 1200px;
  width: 95%;
  font: 18px/1.5 sans-serif;
}

.example-wrapper code {
  background: #F5F5F5;
  padding: 2px 6px;
}

.table-striped tr {
  height: 60px;
}

.table-striped th {
  text-align: left;
}

.box-shadow-primary {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border-spacing: 20px 0rem;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10%;
  margin-left: 10%;
  background-color: $tertiary-color;
  box-shadow: 12px 12px 2px 1px $primary-color;

  & > * {
    flex: 1
  }
}

.block-title {
  white-space: normal;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.block-center {
  display: flex;
  justify-content: center;
}

.block-contenu {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-conteneur-default {
  flex: 0.9;
  padding-top: 120px;

  @media only screen and (min-width: $breakpoint-tablet) {
    margin-top: 70px;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    margin-top: 30px;
  }
}

.form-control {
  border: none;
  height: auto;
  padding: 15px;
  width: 100%;
  background-color: $primary-color-opacity-xmin;
  color: $primary-color;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}

.block-label {
  margin-top: 20px;
  margin-bottom: 20px;
}


.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p, .alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.alert {
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);
}

.alert .sign {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
  text-align: center;
  width: 25px;
  display: inline-block;
}

.alert-success {
  background-color: #dbf6d3;
  border-color: #aed4a5;
  color: #569745;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #98cce6;
  color: #3a87ad;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #f1daab;
  color: #c09853;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #e0b1b8;
  color: #b94a48;
}

.alert-white {
  background-image: linear-gradient(to bottom, #FFFFFF, #F9F9F9);
  border-top-color: #d8d8d8;
  border-bottom-color: #bdbdbd;
  border-left-color: #cacaca;
  border-right-color: #cacaca;
  color: #404040;
  padding-left: 61px;
  position: relative;
}

.alert-white .icon {
  text-align: center;
  width: 45px;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid #bdbdbd;
}

.alert-white .icon:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #bdbdbd;
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  top: 50%;
  right: -6px;
  margin-top: -5px;
  background: #fff;
}

.alert-white.rounded {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon i {
  font-size: 20px;
  color: #FFF;
  left: 12px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.alert-white.alert-danger .icon, .alert-white.alert-danger .icon:after {
  border-color: #ca452e;
  background: #da4932;
}

.alert-white.alert-info .icon, .alert-white.alert-info .icon:after {
  border-color: #3a8ace;
  background: #4d90fd;
}

.alert-white.alert-warning .icon, .alert-white.alert-warning .icon:after {
  border-color: #d68000;
  background: #fc9700;
}

.alert-white.alert-success .icon, .alert-white.alert-success .icon:after {
  border-color: #54a754;
  background: #60c060;
}

.icon-alert {
  color: white;
  padding-top: 15px;
}

.subTitle_block {
  padding: 20px;
  font-style: italic;
}


.menu-deroulant > a:after {
  content: '❯';
  font-size: 15px;
  margin-left: 7px;
  display: inline-block;
}

.sous-menu {
  width: 100%;
  text-align: left;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  max-height: 0;
}

.sous-menu > li > a {
  height: 50px;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  display: flex;
}

nav > ul > li:hover > a {
  color: $primary-color-opacity-xmin;
}

.sous-menu > li:hover {
  background-color: $primary-color-opacity-xmin;
}


@keyframes rotationFleche {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.menu-deroulant:hover > a:after {
  animation: rotationFleche 0.2s linear forwards;
}

@keyframes apparitionSousMenu {
  0% {
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0);
    border-top: 3px solid white;
  }
  30% {
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.3);
  }
  100% {
    max-height: 50em;
    border-top: 3px solid $primary-color;
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.3);
  }
}

.menu-deroulant:hover > .sous-menu {
  animation: apparitionSousMenu 1s forwards;
}

.sous-menu-desktop {
  margin-top: 70px;
  display: none;

}

.block-rigth {
  display: flex;
  justify-content: end;


}

.btn-grid {
  height: 50px;
}


#grid {
  display: grid;
  height: 100px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

form {
  & > div {
    margin-top: 20px;

    label {
      font-size: 20px;
    }
  }
}

.presentation-element {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .element {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .element-name {
      max-width: 250px;
      width: 40%;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.panel-filter{
  margin-bottom: 50px;
  #target {
    background-color: $tertiary-color;
    margin-right: 20px;
    margin-left: 20px;
    padding:20px;
    display:none;
    box-shadow: 10px -10px $primary-color;
  }

  #Show
  {
    display:none;
  }

  .show-hide-btn{
    width: 100%;

    padding: 0px;
  }
}

#in-top:hover {
  cursor: pointer;
}

.not-display-mobile-message {
  display: none;

  @media only screen and (max-width: $breakpoint-tablet-display-profile) {
    display: block;
  }
}

.not-display-mobile {
  @media only screen and (max-width: $breakpoint-tablet-display-profile) {
    display: none;
  }
}

#page-up {
  #first-part {
    position: relative;

    &:before {
      background-image: url("../../public/img/home/HomeApp.png");
      background-size: cover;
      content: '';
      position: absolute;
      top: -15px;
      left: -6%;
      width: 112%;
      height: 102%;
      z-index: -1;

      @media only screen and (max-width: $breakpoint-tablet) {
        top: -60px;
        height: 106%;
      }
    }

    &.nologin {
      &:before {
        top: -46px;
        height: 107%;

        @media only screen and (max-width: $breakpoint-tablet) {
          top: -60px;
          height: 106%;
        }
      }
    }
  }
}


